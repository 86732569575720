<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-select v-model="searchValue.siteName" placeholder="请选择拦河闸" size="mini">
            <el-option
              v-for="(item,index) in waterList"
              :key="item.deviceAddress"
              :label="index+1+' '+item.siteName"
              :value="item.siteName">
            </el-option>
          </el-select>
        <!-- <el-input
          placeholder="请输入拦河闸名称"
          v-model="searchValue.siteName"
        ></el-input> -->
        <el-date-picker
          :editable="false"
          style="margin-left: -10px"
          v-model="searchValue.searhTime"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          prefix-icon="el-icon-time"
          clear-icon=""
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleExport">导出</el-button>
      </div>
    </div>
    <div>
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="siteName" label="拦河闸名称"> </el-table-column>
        <el-table-column prop="gateName" label="闸门名称"> </el-table-column>
        <el-table-column prop="openTime" label="开启时间"> </el-table-column>
        <el-table-column prop="closeTime" label="关闭时间"> </el-table-column>
        <el-table-column prop="runTime" label="本次运行时长"> </el-table-column>
        <el-table-column prop="traffic" label="本次流量(m³)"> </el-table-column>
        <el-table-column prop="gateTrafficSum" label="累计流量(m³)"> </el-table-column>
        <el-table-column prop="closeEmp" width="150" label="负责人">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.pageNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {siteGetSiteAll} from "../../../api/index";
export default {
  components: {},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      tableData: [],
       waterList: [],//拦河闸下拉
      pagination: {
        size: 10,
        pageNum: 1,
        total: 0
      },
      searchValue: {
        siteName: "",
        searhTime: [],
        startTime: "",
        endTime: ""
      },
      loading: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 列表
    getList() {
      this.loading = true;
      let { pageNum, size } = this.pagination;
      this.$get(`/gateUpRecordItem/page/${pageNum}/${size}`).then(res => {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
        this.loading = false;
      });
       siteGetSiteAll().then(res=>{
        this.waterList=res.data
      })
    },
    // 分页
    handleCurrent(page) {
      this.loading = true;
      this.pagination.pageNum = page;
      this.pagination.size = 10;
      let { pageNum, size } = this.pagination;
      let { endTime, startTime, siteName } = this.searchValue;
      if (this.searchValue.searhTime.length) {
        startTime = this.searchValue.searhTime[0];
        endTime = this.searchValue.searhTime[1];
      } else {
        startTime = "";
        endTime = "";
      }
      this.$get(`/gateUpRecordItem/page/${pageNum}/${size}`, {
        siteName,
        endTime,
        startTime
      })
        .then(res => {
          console.log(res);
          this.tableData = res.data.list;
          this.pagination.total = res.data.total;
          this.pagination.pageNum = res.data.pageNum;
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 搜索
    handleSearch() {
      let { endTime, startTime, siteName } = this.searchValue;
      if (this.searchValue.searhTime.length) {
        startTime = this.searchValue.searhTime[0];
        endTime = this.searchValue.searhTime[1];
        this.$get("/gateUpRecordItem/page/1/10", {
          siteName,
          endTime,
          startTime
        }).then(res => {
          console.log(res);
          this.tableData = res.data.list;
         this.pagination.total = res.data.total;
          this.pagination.pageNum = res.data.pageNum;
        });
      } else {
        this.$get("/gateUpRecordItem/page/1/10", {
          siteName
        }).then(res => {
          this.tableData = res.data.list;
         this.pagination.total = res.data.total;
          this.pagination.pageNum = res.data.pageNum;
        });
      }
    },
    // 重置
    handleReset() {
      this.pagination.pageNum = 1;
      this.searchValue.siteName = "";
      this.searchValue.searhTime = "";
      this.getList();
    },
    handleExport() {
      let { endTime, startTime, siteName, searhTime } = this.searchValue;
      if (searhTime.length) {
        startTime = searhTime[0];
        endTime = searhTime[1];
        fetch(
          this.$axios.defaults.baseURL +
            `gateUpRecordItem/traffic/export?endTime=${endTime}&startTime=${startTime}&siteName=${siteName}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            console.log(err);
          })
          .then(e => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "闸门统计查询.xlsx";
            a.click();
          });
      } else {
        fetch(
          this.$axios.defaults.baseURL +
            `gateUpRecordItem/traffic/export?endTime=${endTime}&startTime=${startTime}&siteName=${siteName}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            console.log(err);
          })
          .then(e => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "闸门统计查询.xlsx";
            a.click();
          });
      }
    }
  },
  created() {
    this.getList();
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
::v-deep .el-range-input {
  background: #f3f4f8 !important;
}
::v-deep .el-range-separator {
  background: #f3f4f8 !important;
}
::v-deep .el-date-editor.el-range-editor.el-input__inner {
  width: calc(265 / 1920 * 100vw) !important ;
}
</style>
